import React, { useEffect, useState, Suspense } from "react";
import logo from "./img/logo.svg";
import img1 from "./img/1.jpeg";
import img2 from "./img/2.jpeg";
import img3 from "./img/3.jpeg";
import img4 from "./img/4.jpeg";
import img5 from "./img/5.jpeg";
import img6 from "./img/6.jpeg";
import img8 from "./img/8.jpeg";
import img9 from "./img/9.jpeg";
import img10 from "./img/10.jpeg";
import img11 from "./img/11.jpeg";
import img12 from "./img/12.jpeg";
import img13 from "./img/13.jpeg";
import img14 from "./img/14.jpeg";
import img15 from "./img/15.jpeg";
import img16 from "./img/16.jpeg";
import img17 from "./img/17.jpeg";

import "./css/App.css";
import { initializeInteraction } from "./js/index.js";

function App() {
  useEffect(() => {
    setTimeout(() => {
      initializeInteraction();
    }, 0);
  }, []);

  return (
    <main>
      <div className="frame">
        <h1 className="frame__title">İletişim Bilgileri</h1>
        <nav className="frame__links">
          <a href="mailto:info@sportagtr.com">Info</a>
          <a href="mailto:destek@sportagtr.com">Destek</a>
          <a href="https://twitter.com/sportagtr">Twitter</a>
        </nav>
      </div>

      <nav className="menu">
        <span className="menu__item oh">
          <a className="menu__item-link oh__inner" href="#details-1">
            Hakkında
          </a>
        </span>
        <span className="menu__item oh">
          <a className="menu__item-link oh__inner" href="#details-2">
            Projeler
          </a>
        </span>
        <span className="menu__item oh">
          <a className="menu__item-link oh__inner" href="#details-3">
            Şampiyonun Kadrosu
          </a>
        </span>
        <button
          className="close close--menu oh unbutton"
          aria-label="Close menu"
        >
          <span className="oh__inner">&#10005;</span>
        </button>
      </nav>

      <button className="menu-link oh unbutton underline">
        <span className="oh__inner">Menu</span>
      </button>

      <h2 className="heading oh">
        <span className="oh__inner">
          We are <strong>Sportag</strong>
        </span>
      </h2>

      <p className="content-primary">
        Trabzon’da faaliyet gösteren sportif bir reklam ve organizasyon
        şirketidir.
      </p>

      <p className="content-secondary">
        Kaşüstü Mah. Devlet Karayolu Cad. No:64A <br />
        YOMRA / TRABZON
      </p>

      <div className="deco" aria-hidden="true">
        <div className="deco__img-wrap deco__img-wrap--upper">
          <div className="deco__img deco__img--upper"></div>
        </div>
        <div className="deco__img-wrap deco__img-wrap--lower">
          <div className="deco__img deco__img--lower"></div>
        </div>
      </div>

      <section className="details-wrap">
        <div className="details" id="details-1">
          <div className="panel">
            <img src={img1} className="panel__img pos-1" alt="sportag" />
            <img src={img2} className="panel__img pos-2" alt="sportag" />
            <img src={img3} className="panel__img pos-3" alt="sportag" />
            <img src={img4} className="panel__img pos-4" alt="sportag" />
            <img src={img5} className="panel__img pos-5" alt="sportag" />
            <img src={img6} className="panel__img pos-6" alt="sportag" />
            <img src={img8} className="panel__img pos-8" alt="sportag" />
            <img src={img9} className="panel__img pos-9" alt="sportag" />
            <img src={img10} className="panel__img pos-10" alt="sportag" />
            <img src={img11} className="panel__img pos-11" alt="sportag" />
            <img src={img12} className="panel__img pos-12" alt="sportag" />
            <img src={img13} className="panel__img pos-13" alt="sportag" />
            <img src={img14} className="panel__img pos-14" alt="sportag" />
            <img src={img15} className="panel__img pos-15" alt="sportag" />
            <img src={img16} className="panel__img pos-16" alt="sportag" />
            <img src={img17} className="panel__img pos-17" alt="sportag" />
          </div>
          <div className="details__content">
            <h3 className="details__content-title oh">
              <span className="oh__inner">Hakkında</span>
            </h3>
            <p className="details__content-text">
              Trabzon’da faaliyet gösteren sportif bir reklam ve organizasyon
              şirketidir.
            </p>
            <a className="details__content-link oh">
              <span className="oh__inner">+ Tutkuyla</span>
            </a>
          </div>
        </div>
        <div className="details" id="details-2">
          <div className="panel">
            <img src={img1} className="panel__img pos-1" alt="sportag" />
            <img src={img2} className="panel__img pos-2" alt="sportag" />
            <img src={img3} className="panel__img pos-3" alt="sportag" />
            <img src={img4} className="panel__img pos-4" alt="sportag" />
            <img src={img5} className="panel__img pos-5" alt="sportag" />
            <img src={img6} className="panel__img pos-6" alt="sportag" />
            <img src={img8} className="panel__img pos-8" alt="sportag" />
            <img src={img9} className="panel__img pos-9" alt="sportag" />
            <img src={img10} className="panel__img pos-10" alt="sportag" />
            <img src={img11} className="panel__img pos-11" alt="sportag" />
            <img src={img12} className="panel__img pos-12" alt="sportag" />
            <img src={img13} className="panel__img pos-13" alt="sportag" />
            <img src={img14} className="panel__img pos-14" alt="sportag" />
            <img src={img15} className="panel__img pos-15" alt="sportag" />
            <img src={img16} className="panel__img pos-16" alt="sportag" />
            <img src={img17} className="panel__img pos-17" alt="sportag" />
          </div>
          <div className="details__content">
            <h3 className="details__content-title oh">
              <span className="oh__inner">Projeler</span>
            </h3>
            <p className="details__content-text">
              {" "}
              Şampiyonun Kadrosu projemiz ile Trabzonspor taraftarlarını hiç olmadığı kadar yakına, bir
              araya getiriyoruz. Taraftarıyla, futbolcusuyla, bordo maviye gönül
              vermiş milyonlarıyla bu kadro, şampiyonluk kadrosu…{" "}
            </p>
            <a className="details__content-link oh">
              <span className="oh__inner">+ Devamı</span>
            </a>
          </div>
        </div>
        <div className="details" id="details-3">
          <div className="panel">
            <img src={img1} className="panel__img pos-1" alt="sportag" />
            <img src={img2} className="panel__img pos-2" alt="sportag" />
            <img src={img3} className="panel__img pos-3" alt="sportag" />
            <img src={img4} className="panel__img pos-4" alt="sportag" />
            <img src={img5} className="panel__img pos-5" alt="sportag" />
            <img src={img6} className="panel__img pos-6" alt="sportag" />
            <img src={img8} className="panel__img pos-8" alt="sportag" />
            <img src={img9} className="panel__img pos-9" alt="sportag" />
            <img src={img10} className="panel__img pos-10" alt="sportag" />
            <img src={img11} className="panel__img pos-11" alt="sportag" />
            <img src={img12} className="panel__img pos-12" alt="sportag" />
            <img src={img13} className="panel__img pos-13" alt="sportag" />
            <img src={img14} className="panel__img pos-14" alt="sportag" />
            <img src={img15} className="panel__img pos-15" alt="sportag" />
            <img src={img16} className="panel__img pos-16" alt="sportag" />
            <img src={img17} className="panel__img pos-17" alt="sportag" />
          </div>
          <div className="details__content">
            <h3 className="details__content-title oh">
              <span className="oh__inner">Şampiyonun Kadrosu</span>
            </h3>
            <p className="details__content-text">
              Sen de hemen kaydol, fotoğrafını yükle, stadyumda
              Şampiyonun Kadrosu'nda yerini almak için ismini ve resmini içeren sana özel Karo'yla yerini
              almış sınırlı sayıda kişiden biri olma şansını
              kaçırma!
            </p>
            <a
              href="https://sampiyonunkadrosu.trabzonspor.org.tr"
              className="details__content-link oh"
            >
              <span className="oh__inner">+ Hemen Dene</span>
            </a>
          </div>
        </div>
        <button
          className="close close--details oh unbutton"
          aria-label="Close details"
        >
          <span className="oh__inner">&#10005;</span>
        </button>
      </section>
    </main>
  );
}

export default App;
